.PhoneInputInput {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  outline: none;
}

.PhoneInputInput:focus-visible {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
